import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useStyles from './styles'
import Image from 'next/image'
export interface IconItemProps {
  copy: string
  src: string
  alt?: string
  'data-test-id': string
}

export default function IconItem({
  copy,
  src,
  alt = 'icon',
  'data-test-id': testId,
}: IconItemProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile })
  return (
    <Grid
      item
      sm
      xs={12}
      className={classes.container}
      data-test-id={testId}
    >
      <div className={classes.iconWrapper}>
        <Image src={src} alt={alt} fill />
      </div>
      <Typography className={classes.copy}>{copy}</Typography>
    </Grid>
  )
}
