import { makeStyles } from '@mui/styles'

export default makeStyles({
  root: {
    paddingBottom: '24px',
  },
  focusForHelperText: {
    '&:focus-within .helperText': {
      display: 'block',
    },
  },
  prefix: {
    whiteSpace: 'nowrap',
    paddingRight: '4px',
    color: 'rgb(131,131,131)',
    fontSize: '16px',
    fontWeight: 'normal',
  },
  errorText: {
    display: 'block',
  },
})
