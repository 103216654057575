import useSWR from 'swr'
import React, { useState, RefObject, useEffect } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useTheme } from '@mui/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import useStyles from './styles'
import TextField from 'components/TextField/TextField'
import RadioGroup from 'components/RadioGroup/RadioGroup'
import Select from 'components/Select/Select'
import CheckboxGroup from 'components/CheckboxGroup/CheckboxGroup'
import { PARTNER_SUPPORT_EMAIL } from 'lib/config'
import Button from 'components/Button/Button'
import {
  rbacSchema,
  isManagerOptions,
} from 'lib/validations/requestAccessSchema'
import RetailerSelect from 'lib/fields/RetailerSelect'
import { formatErrors } from 'lib/forms/errors'
import { fetcher } from 'lib/api'
import TrackForm from 'components/Track/TrackForm'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'
import TrackBox from 'components/Track/TrackBox'
import { roleOptions } from 'lib/utils'
import { SWR_CONFIG } from 'lib/config'
import { Store } from 'lib/types/form'
import { Retailer } from 'lib/types/retailer'

type Props = {
  requestAccess: (data: any, payload: any) => Promise<void>
  firstInputRef?: RefObject<HTMLInputElement | null>
  'data-test-id'?: string
}

export default function RequestAccessForm({
  requestAccess,
  firstInputRef,
  'data-test-id': testId = 'landing-create-account-form',
}: Props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [enableManualRetailerEntry, setEnableManualRetailerEntry] =
    useState(false)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [formDirtied, setFormDirtied] = useState(false)

  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    setValue,
    formState,
    watch,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(rbacSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      retailerName: '',
      retailerSelection: [],
      role: '',
      location: null,
      countries: [],
      isManager: '',
      stores: [],
      isStores: false,
    },
  })

  const retailerSelection: Retailer[] = watch('retailerSelection')
  const isManager = watch('isManager')

  const { data: stores } = useSWR(
    retailerSelection.length === 1
      ? `public/${retailerSelection[0].id}/stores`
      : null,
    url => fetcher(url),
    SWR_CONFIG
  )

  useEffect(() => {
    if (formState.isDirty && !formDirtied) {
      setFormDirtied(true)
    }
  }, [formState, formDirtied])

  useEffect(() => {
    setValue('isStores', stores?.data?.items?.length > 0 ? true : false)
  }, [stores])

  const onSubmit = async (data: any) => {
    setLoading(true)
    const { location, countries, isStores, stores, isManager, ...rest } = data
    const payload = {
      ...rest,
      stores,
      isManager,
      retailerSelection: data.retailerSelection.map(
        (retailer: any) => retailer.backendTitle
      ),
    }

    requestAccess(data, payload)

    setLoading(false)
  }

  return (
    <TrackForm
      id="request-access"
      data-test-id={testId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TrackBoxGroup id="user-details">
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TrackBox id="first-name">
              <TextField
                {...field}
                label="First name"
                error={errors?.firstName?.message}
                inputRef={firstInputRef}
                data-test-id={`${testId}-first-name`}
              />
            </TrackBox>
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TrackBox id="last-name">
              <TextField
                {...field}
                label="Last name"
                error={errors?.lastName?.message}
                data-test-id={`${testId}-last-name`}
              />
            </TrackBox>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TrackBox id="email-address">
              <TextField
                {...field}
                error={errors?.email?.message}
                label="Email address"
                data-test-id={`${testId}-email-address`}
              />
            </TrackBox>
          )}
        />
        <Controller
          control={control}
          name="role"
          render={({ field }) => (
            <TrackBox id="company-role">
              <Select
                {...field}
                options={roleOptions}
                value={field.value}
                onChange={field.onChange}
                data-test-id={`${testId}-business-role`}
                label="Select your role in the business"
              />
            </TrackBox>
          )}
        />
      </TrackBoxGroup>
      <TrackBoxGroup id="retailer">
        <Controller
          control={control}
          name="retailerSelection"
          render={({ field }) => (
            <TrackBox id="retailer-select">
              <RetailerSelect
                {...field}
                value={field.value}
                error={formatErrors(
                  errors['retailerSelection'] as unknown as FieldError
                )}
                controlName="retailerSelection"
                register={register}
                setValue={setValue}
                data-test-id={`${testId}-retailer`}
              />
            </TrackBox>
          )}
        />
        <TrackBox id="retailer-not-found">
          <FormControlLabel
            label="I can't find my Retailer"
            control={
              <Checkbox
                color="secondary"
                value={enableManualRetailerEntry}
                onChange={evt =>
                  setEnableManualRetailerEntry(evt.target.checked)
                }
                data-test-id={`${testId}-retailer-not-found`}
              />
            }
          />
        </TrackBox>
        {enableManualRetailerEntry && (
          <Controller
            name="retailerName"
            control={control}
            render={({ field }) => (
              <TrackBox id="retailer-name-manual">
                <TextField
                  {...field}
                  error={errors?.retailerName?.message}
                  label="Retailer name"
                  data-test-id={`${testId}-retailer-name-manual`}
                />
              </TrackBox>
            )}
          />
        )}
      </TrackBoxGroup>
      {retailerSelection.length <= 1 && !enableManualRetailerEntry ? (
        <Grid item xs={12} sm={6}>
          <TrackBoxGroup id="is-manager">
            <Typography
              className={classes.optionsLabel}
              color="primary"
              data-test-id={`${testId}-is-manager-title`}
            >
              Are you a Franchisee or Store/Area Manager?
            </Typography>
            <Controller
              name="isManager"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  options={isManagerOptions}
                  error={errors?.isManager?.message}
                  orientation={isMobile ? 'column' : 'row'}
                  data-test-id={`${testId}-is-manager`}
                />
              )}
            />
          </TrackBoxGroup>
          {retailerSelection.length === 1 &&
            isManager === 'yes' &&
            stores?.data.items.length > 0 && (
              <Grid item xs={12}>
                <TrackBoxGroup id="retailer-stores">
                  <Typography
                    className={classes.optionsLabel}
                    color="primary"
                    data-test-id={`${testId}-stores-title`}
                  >
                    Which Store(s)?
                  </Typography>
                  <Controller
                    name="stores"
                    control={control}
                    render={({ field }) => (
                      <CheckboxGroup
                        {...field}
                        values={field.value}
                        options={
                          stores?.data?.items.length
                            ? [
                                ...stores.data.items.map((v: Store) => ({
                                  label: v.uniqueTitle,
                                  value: v.uniqueTitle,
                                })),
                              ]
                            : []
                        }
                        // @ts-expect-error todo: fix types
                        error={formatErrors(formState.errors?.stores)}
                        orientation={isMobile ? 'column' : 'row'}
                        data-test-id={`${testId}-stores`}
                      />
                    )}
                  />
                </TrackBoxGroup>
              </Grid>
            )}
        </Grid>
      ) : null}
      <Grid item>
        <Typography color="primary">
          The personal information you provide on this page will be used and
          disclosed to set up and administer your Westfield Marketing Hub
          account. For more information about how Scentre Group handles personal
          information, view our Marketing Hub{' '}
          <Link
            href="/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            privacy statement
          </Link>
          .
        </Typography>
      </Grid>
      <Grid item className={classes.submitButton} xs={12}>
        <TrackBoxGroup id="form-actions">
          <TrackBox id="submit-button">
            <Button
              label="Submit"
              color="secondary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              data-test-id={`${testId}-submit-button`}
            />
          </TrackBox>
        </TrackBoxGroup>
      </Grid>
      <TrackBoxGroup id="landing-account-bottom">
        <TrackBox id="trouble-creating-link">
          <Link href={`mailto:${PARTNER_SUPPORT_EMAIL}`}>
            <Typography
              className={classes.helpLink}
              variant={isMobile ? 'body2' : 'body1'}
              data-test-id={`${testId}-trouble-creating-link`}
            >
              Having trouble creating an account?
            </Typography>
          </Link>
        </TrackBox>
      </TrackBoxGroup>
    </TrackForm>
  )
}
