import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useStyles from './styles'
import IconItem, { IconItemProps } from 'components/IconItem/IconItem'

export default function IconItemGroup({
  title,
  iconItems,
  'data-test-id': testId = '',
}: {
  title: string
  iconItems: IconItemProps[]
  'data-test-id'?: string
}) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile })

  return (
    <>
      <Grid item xs={12} className={classes.titleWrapper}>
        <Typography
          component="h2"
          variant="h5"
          className={classes.title}
          color="primary"
          data-test-id={testId ? `${testId}-title` : `title`}
        >
          {title}
        </Typography>
      </Grid>
      {iconItems.map(
        (
          { copy, src, alt, 'data-test-id': iconTestId }: IconItemProps,
          i: number
        ) => (
          <IconItem
            key={i}
            copy={copy}
            src={src}
            alt={alt}
            data-test-id={
              testId ? `${testId}-${iconTestId}-image` : `${iconTestId}-image`
            }
          />
        )
      )}
    </>
  )
}
