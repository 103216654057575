import { makeStyles } from '@mui/styles'
import { SPACING, GUTTER_SPACING } from 'lib/theme'
import { CSSFlexDirection } from 'lib/types/styles'

type Props = { orientation?: CSSFlexDirection }

export default makeStyles((theme) => ({
  radio: {
    width: '45%',
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
  container: {
    marginBottom: theme.spacing(GUTTER_SPACING.XS),
  },
  radioGroup: ({ orientation = 'column' }: Props) => ({
    width: '100%',
    display: 'flex',
    flexDirection: orientation,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }),
  error: {
    color: theme.palette.error.main,
    fontSize: theme.typography.caption.fontSize,
    marginLeft: theme.spacing(SPACING.XS),
  },
}))
