import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

type StyleProps = {
  isMobile?: boolean
}

export default makeStyles((theme) => ({
  container: {
    marginTop: `-${theme?.spacing && theme.spacing(SPACING.XS)}`,
  },
  headerRow: ({ isMobile }: StyleProps) => ({
    background: isMobile
      ? 'url(landing-header-sm.png)'
      : 'url(landing-header-lg.png)',
    backgroundPositionY: isMobile ? 'initial' : 'center',
    backgroundSize: isMobile ? 'initial' : 'cover',
    position: 'relative',
    opacity: 0.99,
  }),
  header: ({ isMobile }: StyleProps) => ({
    color: theme.palette.common.white,
    padding: isMobile ? '74px 0 92px' : '97px 0 50px',
  }),
  rowChild: ({ isMobile }: StyleProps) => ({
    margin: '0 auto',
    maxWidth: isMobile ? '87%' : `${theme?.breakpoints?.values?.sm}px`,
  }),
  headerImage: {
    background: 'rgba(0,0,0,0.64)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    zIndex: -1,
  },
  actionRow: ({ isMobile }: StyleProps) => ({
    fontWeight: 'bold',
    padding: isMobile ? 0 : '42px 0 79px',
    background: isMobile ? 'white' : '#f0f0ea',
    position: 'relative',
  }),
  iconsRow: ({ isMobile }: StyleProps) => ({
    fontWeight: 'bold',
    padding: isMobile ? 0 : '80px 0 79px',
    position: 'relative',
  }),
  boxPattern: {
    position: 'absolute',
    width: '45px',
    height: '45px',
    background: "url('/pattern-red.png') no-repeat top left",
    backgroundSize: '45px 45px',
    left: 0,
    bottom: 0,
  },
  boxRed: {
    position: 'absolute',
    width: '45px',
    height: '45px',
    background: theme?.palette?.secondary?.main,
    left: 0,
    top: 0,
  },
  boxTitle: ({ isMobile }: StyleProps) => ({
    position: 'absolute',
    width: '16px',
    height: isMobile ? '114px' : '135px',
    background: 'white',
    left: 0,
    top: isMobile ? '80px' : '100px',
  }),
  title: ({ isMobile }: StyleProps) => ({
    color: theme.palette.common.white,
    fontWeight: 'bold',
    paddingTop: 0,
    ...(!isMobile && {
      fontSize: '42px',
      lineHeight: '46px',
      maxWidth: '582px',
    }),
    ...(isMobile && {
      width: '80%',
      margin: 'auto',
    }),
  }),
  titleDescription: ({ isMobile }: StyleProps) => ({
    padding: isMobile ? 0 : '0 246px 0 0',
    fontWeight: 'normal',
    ...(isMobile && {
      marginTop: '26px',
      marginBottom: '44px',
    }),
  }),
  actionItem: ({ isMobile }: StyleProps) => ({
    ...(isMobile && {
      marginBottom: theme.spacing(SPACING.M),
    }),
  }),
  formTitle: ({ isMobile }: StyleProps) => ({
    ...(isMobile && {
      marginTop: theme.spacing(SPACING.S),
    }),
    marginBottom: theme.spacing(SPACING.S),
  }),
  formRow: {
    marginBottom: theme.spacing(SPACING.L),
  },
}))
