import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

export default makeStyles(theme => ({
  optionsLabel: {
    fontWeight: 'bold',
    marginTop: theme.spacing(SPACING.S),
    marginBottom: theme.spacing(SPACING.XXS),
  },
  submitButton: {
    marginTop: theme.spacing(SPACING.M),
  },
  helpLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: theme.spacing(SPACING.M),
    '&::hover': {
      textDecoration: 'none',
    },
    cursor: 'pointer',
  },
}))
