import { useAuth0, withAuth0 } from '@auth0/auth0-react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles'
import classnames from 'classnames'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { RecoilRoot } from 'recoil'
import Button from 'components/Button/Button'
import IconItemGroup from 'components/IconItemGroup/IconItemGroup'
import Layout from 'components/Layout/Layout'
import RequestAccessForm from 'components/RequestAccessForm/RequestAccessForm'
import TrackBox from 'components/Track/TrackBox'
import TrackBoxGroup from 'components/Track/TrackBoxGroup'
import TrackRoot from 'components/Track/TrackRoot'
import useTracking from 'hooks/useTracking'
import { postAPI } from 'lib/api'
import useStyles from 'styles/landingPageStyle'

const ICON_ITEMS = [
  {
    copy: 'Amplify awareness of campaigns and product launches',
    src: '/megaphone-icon.svg',
    'data-test-id': 'feature-megaphone',
  },
  {
    copy: 'Drive store visitation with offers and events',
    src: '/footprint-icon.svg',
    'data-test-id': 'feature-footprint',
  },
  {
    copy: 'Discover new opportunities to participate in Westfield campaigns',
    src: '/lightbulb-icon.svg',
    'data-test-id': 'feature-lightbulb',
  },
  {
    copy: 'Manage digital storefronts on westfield.com.au / co.nz',
    src: '/manage-storefront-icon.svg',
    'data-test-id': 'feature-storefront',
  },
  {
    copy: 'Optimise your marketing activity with tips and support',
    src: '/speech-bubble-icon.svg',
    'data-test-id': 'feature-speech-bubble',
  },
]

const TITLE = `The Westfield Marketing Hub: A simple way to reach more Westfield customers`

const TITLE_DESCRIPTION = `Promote your business and extend your marketing across the powerful
      Westfield ecosystem. The Westfield Marketing Hub is one convenient place
      to submit campaigns for all digital channels and centres.`

const TitleDescription = ({
  description,
  'data-test-id': testId = '',
}: {
  description: string
  'data-test-id'?: string
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile })
  return (
    <Typography
      className={classes.titleDescription}
      variant={isMobile ? 'h6' : 'h5'}
      component="p"
      data-test-id={testId}
    >
      {description}
    </Typography>
  )
}

const Banner = ({
  title,
  description,
  'data-test-id': testId = '',
}: {
  title: string
  description: string
  'data-test-id'?: string
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile })
  return (
    <div className={classes.headerRow}>
      <div className={classes.headerImage} />
      <div className={classes.boxTitle} />
      <div className={classnames(classes.header, classes.rowChild)}>
        <Typography
          className={classes.title}
          color="primary"
          variant={isMobile ? 'h5' : 'h4'}
          component="h1"
          data-test-id={`${testId}-title`}
        >
          {title}
        </Typography>
        {!isMobile && (
          <TitleDescription
            description={description}
            data-test-id={`${testId}-description`}
          />
        )}
      </div>
    </div>
  )
}

function Home() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile })
  const firstInputRef = useRef<HTMLInputElement | null>(null)
  const { loginWithRedirect } = useAuth0()
  const router = useRouter()

  const onLoginButtonClick = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/marketing-hub',
      },
    })
  }

  const onRequestAccessButtonClick = () => {
    firstInputRef?.current?.focus()
  }

  const { trackSubmitSuccess } = useTracking()

  return (
    <Layout>
      <RecoilRoot>
        <TrackRoot isLoginPage={true}>
          <div className={classes.container}>
            <Head>
              <meta name="robots" content="noindex" />
            </Head>
            <Banner
              title={TITLE}
              description={TITLE_DESCRIPTION}
              data-test-id="landing-header"
            />
            <TrackBoxGroup id="landing-user" className={classes.actionRow}>
              {!isMobile && <div className={classes.boxPattern} />}
              <Grid container className={classes.rowChild}>
                {isMobile && (
                  <Grid item xs={12}>
                    <TitleDescription
                      description={TITLE_DESCRIPTION}
                      data-test-id="landing-header-description"
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={5} className={classes.actionItem}>
                  <Typography
                    variant="h5"
                    component="h2"
                    color="primary"
                    data-test-id="request-access-title"
                  >
                    New user?
                  </Typography>
                  <TrackBox id="request-access-button">
                    <Button
                      label="Request access"
                      color="secondary"
                      onClick={onRequestAccessButtonClick}
                      data-test-id="landing-request-access-button"
                    />
                  </TrackBox>
                </Grid>
                {!isMobile && <Grid item sm={2} />}
                <Grid item xs={12} sm={5} className={classes.actionItem}>
                  <Typography
                    variant="h5"
                    component="h2"
                    color="primary"
                    data-test-id="landing-login-title"
                  >
                    Already have an account?
                  </Typography>
                  {isMobile ? (
                    <Typography
                      variant={isMobile ? 'body1' : 'h5'}
                      data-test-id="landing-login-mobile"
                    >
                      Please visit using a desktop or laptop computer to see
                      log-in options for existing users.
                    </Typography>
                  ) : (
                     <TrackBox id="login-button">
                       <Button
                         label="Log-in"
                         color="primary"
                         onClick={onLoginButtonClick}
                         data-test-id="landing-login-button"
                       />
                     </TrackBox>
                   )}
                </Grid>
              </Grid>
            </TrackBoxGroup>
            <div className={classes.iconsRow}>
              {!isMobile && <div className={classes.boxRed} />}
              <Grid item container xs={12} className={classes.rowChild}>
                <IconItemGroup
                  title="Westfield retailers can use the Westfield Marketing Hub to"
                  iconItems={ICON_ITEMS}
                  data-test-id="landing-icon"
                />
              </Grid>
            </div>
            <Grid className={classes.formRow}>
              <Grid item container xs={12} className={classes.rowChild}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    component="h2"
                    className={classes.formTitle}
                    color="primary"
                    data-test-id="landing-create-account-title"
                  >
                    Create a new Westfield Marketing Hub account
                  </Typography>
                  <RequestAccessForm
                    firstInputRef={firstInputRef}
                    requestAccess={async (data, payload) => {
                      try {
                        const res = await postAPI(
                          `request-access`,
                          payload,
                          'post',
                          true,
                        )
                        // eslint-disable-next-line no-magic-numbers
                        if (res.status === 200) {
                          router.push({
                            pathname: '/confirm-request-access',
                            query: { name: data.firstName },
                          })
                          trackSubmitSuccess('request-access')
                        }
                      } catch (err) {
                        console.error(`[submit request-access] err`, err)
                      }
                    }}
                    data-test-id="landing-create-account-form"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </TrackRoot>
      </RecoilRoot>
    </Layout>
  )
}

export default withAuth0(Home)
