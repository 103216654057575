import { makeStyles } from '@mui/styles'

type StyleProps = {
  isMobile?: boolean
}

// TODO: We don't seem to have appropriate sizes for these elements that can be imported.
// Discussion should be had around this.
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
  },
  iconWrapper: {
    width: '84px',
    height: '84px',
    position: 'relative',
  },
  copy: ({ isMobile }: StyleProps) => ({
    paddingTop: '5px',
    color: theme?.palette?.primary?.main,
    ...(isMobile && {
      maxWidth: '200px',
    }),
  }),
}))

export default useStyles
